<template>
    <div class="operation-entrance full-page">
      <CommonTitle :titles="titles">
      </CommonTitle>
      <div class="row wrapper">
        <div class="col col-column left">
          <div class="row row-pic">
            <div class="col col-left">
              <span class="title">*上传图片：</span>
              <upload-image
                @upload-success="uploadSuccess"
                :pic="pic"
                @preview="previewImg"
                @delete="deleteImg"
                :is-read-only="readOnly">
              </upload-image>
            </div>
            <div class="input-hint" v-if="!pic">仅支持片格式： .jpg .jpeg .png ，限上传一张</div>
          </div>

          <div class="row">
            <div class="col">
              <span class="title">*标题：</span>
              <a-input v-model="title" :disabled="readOnly" placeholder="请输入标题"></a-input>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <span class="title">*APP对应KEY：</span>
              <a-input v-model="appKey" :disabled="readOnly" placeholder="请输入key"></a-input>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <span class="title">*排序：</span>
              <a-input v-model="sort" :disabled="readOnly" placeholder="排序"></a-input>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <span class="title">h5跳转链接：</span>
              <a-input v-model="jump_url" :disabled="readOnly" placeholder="h5跳转链接"></a-input>
            </div>
          </div>
        </div>

        <div class="col col-column right">
          <div class="row no-bottom-padding">
            <div class="col col-left">
              <span class="title">白名单：</span>
              <a-radio-group v-model="isWhite" name="is_white" :disabled="readOnly">
                <a-radio :value="1">开启</a-radio>
                <a-radio :value="0">关闭</a-radio>
              </a-radio-group>
            </div>
          </div>

          <div class="row no-top-padding">
            <div class="col">
              <span class="title"></span>
              <a-input v-model="whiteUids" :disabled="readOnly" placeholder="白名单用户 ID"></a-input>
            </div>
            <div class="input-hint">请填入用户 ID, 逗号分隔。默认携带 token</div>
          </div>

          <div class="row">
            <div class="col col-left">
              <span class="title">状态：</span>
              <a-radio-group v-model="isStatus" name="isStatus" :disabled="readOnly">
                <a-radio :value="1">开启</a-radio>
                <a-radio :value="0">关闭</a-radio>
              </a-radio-group>
            </div>
          </div>

          <div class="row">
            <div class="col col-left">
              <span class="title">是否携带token：</span>
              <a-radio-group v-model="is_token" name="is_token" :disabled="readOnly">
                <a-radio :value="1">开启</a-radio>
                <a-radio :value="0">关闭</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-30 ph-20">
        <div class="col col-left">
          <a-button
            v-if="operationType !== '2'"
            type="primary"
            @click="handleSave">
            {{buttonText}}
          </a-button>
          <a-button @click="handleCancel">取消</a-button>
        </div>
      </div>
      <ImageViewer ref="imageViewerRef" :url="previewImageUrl"/>
    </div>
</template>

<script>
  import CommonTitle from '@components/CommonTitle';
  import UploadImage from "@components/UploadImage";
  import ImageViewer from '@components/ImageViewer';
  import { handleAddIndexConfig, doGetEntranceDetail, handleEditIndexConfig } from '@/api/indexConfig/Index';
  export default {
    name: "OperationEntrance",
    components: {
      CommonTitle,
      UploadImage,
      ImageViewer
    },
    computed: {
      titles() {
        const pageType = this.$route.query.type;
        if (pageType === '0') {
          return [{title: '入口管理'}, {title: '入口新增'}];
        } else if (pageType === '1') {
          return [{title: '入口管理'}, {title: '入口编辑'}];
        } else if (pageType === '2') {
          return [{title: '入口管理'}, {title: '入口详情'}];
        } else {
          return [];
        }
      },
      buttonText() {
        const pageType = this.$route.query.type;
        if (pageType === '0') {
          return '保存';
        } else if (pageType === '1') {
          return '修改';
        } else {
          return ''
        }
      }
    },
    data() {
      return {
        operationType: this.$route.query.type,
        entranceId: this.$route.query.id,
        readOnly: false,
        pic: '', // 图标
        title: '', // 标题
        appKey: '', // app对应的key
        previewImageUrl: '',
        sort: 1,
        isWhite: 1,
        isStatus: 1,
        whiteUids: '',
        jump_url: '',
        is_token: 1,
      }
    },
    created() {
      if (this.operationType !== '0') {
        this.handleGetEntranceDetail();
      }
    },
    methods: {
      /* 上传图片成功 */
      uploadSuccess(val) {
        this.pic = val?.url || '';
      },
      /* 获取首页导航入口详情 */
      async handleGetEntranceDetail() {
        const data = await doGetEntranceDetail(this.entranceId);
        if (!data || data.error_code) {
          this.$message.error(data.message);
          return;
        }
        console.log(data);
        this.title = data.data.title || '';
        this.pic = data.data.icon || '';
        this.appKey = data.data.key || '';
        this.sort = data.data.sort;
        this.isWhite = data.data.is_white || 0;
        this.isStatus = data.data.status || 0;
        this.whiteUids = data.data.white_uids || '';
        this.jump_url = data.data.jump_url || '';
        this.is_token = data.data.is_token || 0;
        if (this.operationType === '2') {
          this.readOnly = true;
        }
      },
      deleteImg() {
        this.pic = '';
      },
      previewImg() {
        this.previewImageUrl = this.pic;
        this.$refs.imageViewerRef?.show && this.$refs.imageViewerRef.show();
      },
      /* 保存 */
      handleSave() {
        if (this.pic === '') {
          this.$message.warning('请上传图片！');
          return false;
        }
        if (this.title === '') {
          this.$message.warning('请输入标题！');
          return false;
        }
        if (this.appKey === '') {
          this.$message.warning('请输入APP对应Key！');
          return false;
        }
        if (!this.sort && this.sort !== 0) {
          this.$message.warning('请填写排序！');
          return false;
        }
        if (this.isWhite && this.whiteUids === '') {
          this.$message.warning('请填写白名单用户ID！');
          return false;
        }
        if (this.operationType === '0') {
          this.goAdd();
        } else if (this.operationType === '1') {
          this.goEdit();
        }
      },
      /* 去新增 */
      async goAdd() {
        console.log('去新增');
        const params = {
          title: this.title,
          icon: this.pic,
          key: this.appKey,
          sort: this.sort,
          is_white: this.isWhite,
          white_uids: this.whiteUids,
          status: this.isStatus,
          jump_url: this.jump_url,
          is_token: this.is_token,
        }
        const data = await handleAddIndexConfig(params);

        if (!data || data.error_code) {
          this.$message.error(data.message);
          return;
        }
        this.$message.success(data.message);
        await this.$router.push('/index/entrance/list')
      },
      /* 去修改 */
      async goEdit() {
        console.log('去修改啦');
        const params = {
          title: this.title,
          icon: this.pic,
          key: this.appKey,
          sort: this.sort,
          is_white: this.isWhite,
          white_uids: this.whiteUids,
          status: this.isStatus,
          jump_url: this.jump_url,
          is_token: this.is_token,
        }
        const data = await handleEditIndexConfig(params, this.entranceId);

        if (!data || data.error_code) {
          this.$message.error(data.message);
          return;
        }
        this.$message.success(data.message);
        await this.$router.push('/index/entrance/list')
      },
      /* 取消 */
      handleCancel() {
        console.log('取消');
        this.$router.push('/index/entrance/list');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .operation-entrance {
    overflow-y: auto;

    .row-pic {
      height: 150px;
    }

    .cover-img {
      max-width: 300px;
      height: 104px;
    }

    .row {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    .ph-20 {
      padding-left: 100px;
      padding-right: 20px;
    }

    .wrapper {
      justify-content: flex-start;
      align-items: flex-start;
    }

    .left {
      margin-right: 50px;

      .col {
        min-width: 240px;
      }
    }

    .left, .right {
      .row {
        position: relative;
      }

      .col {
        height: 70px;
      }
    }

    .input-hint {
      position: absolute;
      left: 105px;
      bottom: 0;
      font-size: 12px;
      color: $light02;
    }

    .title {
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      text-align: right;
    }

    .extra {
      margin-left: 10px;
    }
  }
</style>
