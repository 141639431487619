<template>
  <a-modal title="进度统计"
           :visible="shown"
           :confirm-loading="loading"
           :mask-closable="false"
           :width="900"
           :centered="true"
           :destroy-on-close="true"
           wrap-class-name="learn-map-statistic-modal"
           @ok="close(false)"
           @cancel="close(false)">
    <a-table :columns="columns" :data-source="list" :pagination="false" :loading="loading" bordered
             row-key="username" class="table small-cell-table"></a-table>
  </a-modal>
</template>

<script>
import { getMapStatistic } from '@/api/learnMap/Index';

const columns = [
  {
    width: '80px',
    align: 'center',
    title: '姓名',
    dataIndex: 'truename',
    key: 'truename',
  },
  {
    width: '180px',
    align: 'center',
    title: '岗位',
    dataIndex: 'postname',
    key: 'postname',
  },
  {
    width: '100px',
    align: 'center',
    title: '电话',
    dataIndex: 'username',
    key: 'username',
  },
  {
    width: '180px',
    align: 'center',
    title: '大区',
    dataIndex: 'orgname',
    key: 'orgname',
  },
  {
    width: '180px',
    align: 'center',
    title: '体验中心',
    dataIndex: 'uporgname',
    key: 'uporgname',
  },
  {
    width: '120px',
    align: 'center',
    title: '已完成最后关卡',
    dataIndex: 'level_progress',
    key: 'level_progress',
  },
];

export default {
  name: 'MapStatistic',
  data() {
    return {
      loading: false,

      shown: false,
      data: {},

      list: [],

      columns: columns,
    };
  },
  methods: {
    show({ record }) {
      this.data = record || {};
      this.shown = true;

      this.data?.id && this.getMapStatistic();
    },

    close() {
      this.shown = false;
      this.data = {};
      this.list = [];
    },

    async getMapStatistic() {
      if (this.loading) {return;}
      this.loading = true;

      const data = await getMapStatistic({ id: this.data.id }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '查询失败');
      }
      this.list = data?.data || [];
    },
  },
};
</script>

<style scoped lang="scss">
.learn-map-statistic-modal {
}
</style>
