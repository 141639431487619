import request from '@/utils/Request';

// 用户配置-后台-新增认证等级
export function handleAddIndexConfig(data) {
  return request({
    url: '/navigation/admin/create',
    method: 'post',
    data,
  });
}

// 用户配置-后台-首页导航入口列表
export function getIndexConfigList() {
  return request({
    url: '/navigation/admin',
    method: 'get'
  });
}

// 用户配置-后台-删除首页导航入口
export function doDelEntrance(id) {
  const url = `/navigation/admin/${id}`;
  return request({
    url,
    method: 'delete'
  });
}

// 用户配置-后台-获取首页导航详情
export function doGetEntranceDetail(id) {
  const url = `/navigation/admin/${id}`;
  return request({
    url,
    method: 'get'
  });
}

// 用户配置-后台-修改首页导航
export function handleEditIndexConfig(data, id) {
  const url = `/navigation/admin/${id}`;
  return request({
    url,
    method: 'post',
    data,
  });
}
