<template>
  <BaseLabelAction custom-class="app-select-shelve-status">
    <span slot="label" v-if="label">{{ label }}</span>

    <a-select v-model="selectedId" :disabled="disabled" :placeholder="placeholder" class="select" @change="select">
      <a-select-option v-for="item of options" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
    </a-select>
  </BaseLabelAction>
</template>

<script>
import BaseLabelAction from '@components/BaseLabelAction';


const options = [
  {id: -1, name: '全部状态'},
  {id: 1, name: '已上架'},
  {id: 0, name: '未上架'},
];

export default {
  name: 'SelectMapStatus',
  components: {BaseLabelAction},
  props: {
    label: {type: String, default: '状态'},
    placeholder: {type: String, default: '请选择状态'},
    defaultSelectedId: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
  },
  watch: {
    defaultSelectedId() {
      this.setDefault();
    },
  },
  data() {
    return {
      options: options,
      selectedId: -1,
    }
  },
  created() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      this.selectedId = !this.defaultSelectedId ? -1 : +this.defaultSelectedId;
    },

    select() {
      if (!this.options || !this.options.length) {
        return;
      }

      const [item] = this.options.filter(o => o.id === this.selectedId);
      this.$emit('select', item);
    },
    reset() {
      this.selectedId = -1;
    }
  },
}
</script>

<style scoped lang="scss">
.app-select-shelve-status {

  .select {
    width: 160px;
    min-width: 160px;
    max-width: 160px;
  }
}
</style>
