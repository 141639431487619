<template>
  <div>
    <a-upload :multiple="false" accept=".jpg, .jpeg, .png"
              list-type="picture-card"
              :disabled="isReadOnly"
              :show-upload-list="false"
              :custom-request="customRequest"
              :beforeUpload="beforeUpload">
      <img v-if="imgPic" :src="imgPic" alt="封面" class="cover-img"/>
      <div v-else>
        <a-icon :type="loading ? 'loading' : 'plus'"/>
        <div class="ant-upload-text">上传</div>
      </div>
    </a-upload>
    <div class="col col-left" v-if="imgPic" style="display: flex;">
      <a-button @click="previewImg">查看</a-button>
      <a-button type="danger" @click="deleteCoverImg">删除</a-button>
    </div>
  </div>
</template>

<script>
  import {getImageUrl} from '@/api/Common'
  export default {
    name: "UploadImage",
    props: {
      isReadOnly: {
        type: Boolean,
        default() {
          return false;
        }
      },
      pic: {
        type: String,
        default() {
          return ''
        }
      },
      levelIndex: {
        type: Number,
        default() {
          return -1
        }
      }
    },
    watch: {
      pic: {
        handler(newVal, oldVal) {
          if (oldVal !== newVal) {
            this.imgPic = newVal;
          }
        },
        immediate: true
      }
    },
    data() {
      return {
        uploadingPic: false,
        loading: false,
        imgPic: ''
      }
    },
    methods: {
      customRequest({file}) {
        this.uploadPic(file);
      },
      beforeUpload(file) {
        const types = ['jpg', 'jpeg', 'png'];
        const type = file.type;
        const size = file.size;

        return new Promise((resolve, reject) => {
          if (types.every(t => !type.includes(t))) {
            this.$message.info('图片仅支持 .jpg .jpeg .png 格式，请重新选择图片');
            return reject('ImageTypeError');
          }

          if (size > 1024 * 1024) {
            this.$message.info('图片大小不能超过 1M，请重新选择图片');
            return reject('ImageSizeError');
          }

          const imgUrl = URL.createObjectURL(file);
          const imgEl = new Image();
          imgEl.src = imgUrl;
          imgEl.onload = () => {
            return resolve();
          }
        });
      },
      /* 上传文件 */
      async uploadPic(data) {
        const params = new FormData();
        params.append('file', data);
        const response = await getImageUrl(params);
        console.log(response);
        if (response.code === 200) {
          this.imgPic = response?.url || '';
          const obj = {
            url: response.url,
            ind: this.levelIndex
          }
          this.$emit('upload-success', obj);
        } else  {
          this.$message.error({content: response.msg});
        }
      },

      previewImg() {
        this.$emit('preview');
      },
      deleteCoverImg() {
        this.$emit('delete');
      },
    }
  }
</script>

<style lang="scss" scoped>
  .cover-img {
    width: 104px;
    height: 104px;
  }
</style>
