<template>
  <div>
    <CommonTitle :titles="titles"/>

    <div v-for="(item) in list" :key="item.id" class="mb-30 ph-20">
      <a-card>
        <div slot="title">{{ item.title }}</div>
        <div class="mb-10">
          <a-month-picker v-if="item.monthShown"
                          v-model="item.month"
                          :allow-clear="false"/>
        </div>
        <div class="mb-10">
          <a-upload name="file"
                    :multiple="false"
                    :file-list="item.fileList"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    :headers="{'X-Auth-Token': token}"
                    :data="{month: parseDate(item.month)}"
                    :disabled="uploading"
                    :action="item.uploadUrl"
                    @change="handleChange($event, item)">
            <a-button class="btn">点击上传文件</a-button>
          </a-upload>
        </div>
        <div>
          <span>注: 文件类型必须为 xlsx，如果没有模板 -> </span>
          <a :href="item.template">点击下载</a>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import CommonTitle from '@/components/CommonTitle.vue';

export default {
  name: 'Upload',
  components: {
    CommonTitle,
  },
  computed: {
    token() {
      return this.$store.state.User.token || '';
    },
    parseDate() {
      return (month) => {
        return month?.format('YYYY-MM') || '';
      };
    },
  },
  data() {
    return {
      titles: [{ title: '地图管理' }, { title: '导入数据' }],

      uploading: false,

      list: [
        {
          id: 1,
          title: '导入用户销售数据',
          uploadUrl: '/studymap/admin/import/user',
          template: 'https://hongqiproimg.wemark.tech/Uploads/Website/1637389677195346777ff4844607981976a4d218418d52a.xlsx',
          fileList: [],
        },
        {
          id: 2,
          title: '导入店单人效率',
          uploadUrl: '/studymap/admin/import/org',
          template: 'https://hongqiproimg.wemark.tech/Uploads/Website/16373897758268782ba1126b54f11af1f8221bcca745865.xlsx',
          fileList: [],
        },
        {
          id: 3,
          title: '导入店双率完成情况',
          uploadUrl: '/studymap/admin/import/orgEfficiency',
          template: 'https://hongqiproimg.wemark.tech/Uploads/Website/163739308618790485190226a474341647016277197e452.xlsx',
          fileList: [],
          monthShown: true,
          month: moment(),
        },
      ],
    };
  },
  methods: {
    handleChange(evt, item) {
      this.uploading = evt.file.status !== 'done';
      item.fileList = [evt.file];
      const response = evt.file?.response || {};
      const error = response.data?.error || [];
      const error2 = error.map((err) => {
        const err2 = {};
        Object.keys(err).forEach((key) => {
          if (key !== 'error') {
            err2.key = err[key];
          } else {
            err2.error = err[key];
          }
        });
        return err2;
      });

      if (evt.file.status === 'done') {
        if (response.error_code) {
          this.$notice({
            title: response?.message || '上传失败',
            content: h => h(
              'div',
              {},
              [
                ...error2.map((i) => {
                  return h(
                    'div',
                    {
                      style: {
                        display: 'flex',
                        'margin-bottom': '5px',
                        'padding-bottom': '5px',
                        'border-bottom': '1px solid #EFEFEF',
                      },
                    },
                    [
                      h(
                        'div',
                        {
                          style: {
                            flex: 'none',
                            width: '150px',
                          },
                        },
                        i.key,
                      ),
                      h(
                        'div',
                        {
                          style: {
                            flex: 'auto',
                          },
                        },
                        [
                          ...i.error.map((err) => h('div', {}, [err])),
                        ],
                      ),
                    ],
                  );
                }),
              ],
            ),
          });
          item.fileList = [];
        } else {
          this.$message.success('上传成功');
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  margin: 0 !important;
}
</style>
