<template>
  <div class="admin-learn-map-detail-level block">
    <div class="flex flex-end mb-10">
      <a-button type="primary" @click="addLevel()">新增关卡</a-button>
    </div>

    <div>
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">
        <template slot="finish_title" slot-scope="text, record">
          {{ record.finish_type_ || '' }}【{{ text }}】
        </template>
        <template slot="unlock" slot-scope="text">
          <div v-for="(item, idx) of text" :key="idx">
            {{ item.unlock_type_ || '' }}{{ item.unlock_title || '' }}{{
              item.unlock_condition_ || ''
            }}{{ item.unlock_length }}{{ item.unlock_unit_ || '' }}
          </div>
        </template>
        <template slot="action" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <a-button type="link" size="small" @click="toViewLevel(record)" v-if="readOnly">查看</a-button>
            <a-button type="link" size="small" @click="addLevel(record)" v-if="!readOnly">编辑</a-button>
            <a-button type="link" size="small" @click="deleteRecord(record)" v-if="!readOnly">删除</a-button>
          </div>
        </template>
      </a-table>
    </div>

    <div class="flex flex-start mt-20">
      <!--<a-button type="primary" @click="save()">保存本页</a-button>-->
      <!--<a-button type="primary" @click="saveAndNext()">保存并下一页</a-button>-->
      <a-button type="primary" @click="next()">下一页</a-button>
      <a-button @click="back">返回列表</a-button>
    </div>

    <LevelDetailModal ref="levelDetailModalRef" @close="levelDetailModalClosed"/>
  </div>
</template>

<script>

import { getLevelList, deleteLevel } from '@/api/learnMap/Index';
import { mapState } from 'vuex';
import LevelDetailModal from '@components/learnMap/LevelDetailModal';

const columns = [
  {
    width: '80px',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: '关卡ID',
  },
  {
    width: '80px',
    align: 'center',
    title: '优先级',
    dataIndex: 'sort',
    key: 'sort',
  },
  {
    width: '220px',
    align: 'center',
    title: '完成条件',
    key: 'finish_title',
    dataIndex: 'finish_title',
    scopedSlots: { customRender: 'finish_title' },
  },
  {
    width: '220px',
    align: 'center',
    title: '解锁条件',
    dataIndex: 'unlock',
    key: 'unlock',
    scopedSlots: { customRender: 'unlock' },
  },
  {
    width: '80px',
    align: 'center',
    title: '奖励积分',
    key: 'integral',
    dataIndex: 'integral',
  },
  {
    width: '120px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'Level',
  components: { LevelDetailModal },
  data() {
    return {
      loading: false,

      id: '',

      columns: columns,
      data: [],
    };
  },
  computed: {
    ...mapState({
      readOnly: state => state.LearnMap.readOnly,
    }),
  },
  created() {
    this.id = this.$route?.query?.id || '';

    this.id && this.getLevelList();
    if (this.$route?.query?.type === '2') {
      this.$store.commit('LearnMap/updateReadOnly', true);
      console.log(this.$store.state.LearnMap.readOnly);
    }
  },
  // destroyed() {
  //   this.$store.commit('LearnMap/updateReadOnly', false);
  // },
  methods: {
    back() {
      this.$router.replace('/index/learnMap/list')
    },

    save() {},
    saveAndNext() {},
    next() {
      this.$router.replace({
        path: '/index/learnMap/detail/verification',
        query: { id: this.id, type: this.$route.query?.type },
      });
    },

    addLevel(record) {
      const options = { mapId: this.id };
      record?.id && (options.levelId = record.id + '');

      this.$refs.levelDetailModalRef?.show && this.$refs.levelDetailModalRef.show(options);
    },
    toViewLevel(record) {
      const options = { mapId: this.id };
      record?.id && (options.levelId = record.id + '');

      this.$refs.levelDetailModalRef?.show && this.$refs.levelDetailModalRef.show(options);
    },
    async getLevelList() {
      if (this.loading) {return;}
      this.loading = true;

      const data = await getLevelList({ id: this.id }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '获取关卡列表失败');
      }

      this.data = data?.data || [];
    },

    levelDetailModalClosed(event) {
      event?.needReload && this.getLevelList();
    },

    deleteRecord(record) {
      this.$confirm({
        content: () => <div>确认删除该关卡？</div>,
        onOk: () => this.confirmDeleteRecord(record),
      });
    },
    async confirmDeleteRecord(record) {
      if (this.loading) {return;}
      this.loading = true;

      const data = await deleteLevel({ id: record.id }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '删除失败');
      }
      this.$message.success(data?.message || '删除成功');
      this.getLevelList();
    },
  },
};
</script>

<style scoped lang="scss">
.admin-learn-map-detail-level {
}
</style>
