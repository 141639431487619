<template>
  <div class="ac-map-list full-page">
    <CommonTitle :titles="titles">
      <div slot="buttons" class="btn-group">
        <a-button @click="addPath">添加地图</a-button>
      </div>
    </CommonTitle>
    <div class="search row-wrap">
      <BaseLabelAction class="mr-30 mb-10">
        <span slot="label">地图名称：</span>
        <a-input v-model="pathName" placeholder="输入地图名称" class="input"/>
      </BaseLabelAction>
      <SelectJobLevel ref="jobLevel" class="mr-30 mb-10" @check-job-level="checkJobLevel"/>
      <SelectMapStatus ref="mapStatus" label="状态：" class="mr-30 mb-10" @select="selectStatus"></SelectMapStatus>
      <div class="select-org mb-10">
        <span>组织机构：</span>
        <SelectOrg ref="selectOrg" :show-label="false" :disabled="readOnly" :default-selected-ids="orgIds"
                   @select="selectOrg"></SelectOrg>
      </div>
      <div class="search-btn mb-10">
        <a-button type="primary" @click="onSearch">搜索</a-button>
        <a-button @click="onReset">重置</a-button>
      </div>
    </div>

    <div class="row">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="id" class="table small-cell-table">
        <template slot="post_arr" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <div v-for="(item, index) of text" :key="item.id">
              <template v-if="record.allPostShown || index < listDefaultShownCount">
                <span>{{ item.name }}</span>
                <template v-if="item.level_name_arr && item.level_name_arr.length">
                  <span v-for="(level, idx) of item.level_name_arr" :key="idx">-{{ level }}</span>
                </template>
              </template>
            </div>

            <template v-if="text.length > listDefaultShownCount">
              <a-button type="link" size="small" @click="toggleAllPostShown(record)">
                <span v-if="record.allPostShown">收起全部</span>
                <span v-else>显示全部</span>
              </a-button>
            </template>
          </div>
        </template>
        <template slot="org_arr" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <div v-for="(item, index) of text" :key="item.id">
              <template v-if="record.allOrgShown || index < listDefaultShownCount">
                <span>{{ item.org_name }}</span>
              </template>
            </div>

            <template v-if="text.length > listDefaultShownCount">
              <a-button type="link" size="small" @click="toggleAllOrgShown(record)">
                <span v-if="record.allOrgShown">收起全部</span>
                <span v-else>显示全部</span>
              </a-button>
            </template>
          </div>
        </template>
        <template slot="status" slot-scope="text">
          <span v-if="text === 1">已上架</span>
          <span v-else-if="text === 2">已下架</span>
          <span v-else>未上架</span>
        </template>
        <template slot="updated_at" slot-scope="text">
          {{ formatTime(text) }}
        </template>
        <template slot="action" slot-scope="text, record">
          <div class="table-cell-wrapper">
            <a-button type="link" size="small" @click="toViewItem(record)">查看</a-button>
            <a-button type="link" size="small" v-if="record.status !== 1" @click="editItem(record)">编辑</a-button>
            <a-button v-if="record.status === 1" type="link" size="small" @click="pullOnOff(record, 2)">下架</a-button>
            <template v-else>
              <a-button type="link" size="small" @click="pullOnOff(record, 1)">上架</a-button>
              <a-button type="link" size="small" @click="deleteRecord(record)">删除</a-button>
            </template>
            <a-button type="link" size="small" @click="viewStatistic(record)">进度统计</a-button>
          </div>
        </template>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <MapStatistic ref="mapStatisticRef" :data="record"/>
  </div>
</template>

<script>
import { listSubPage } from '@/utils/Navs/LearnMapNavs';
import CommonTitle from '@components/CommonTitle';
import BaseLabelAction from '@components/BaseLabelAction';
import SelectMapStatus from '@components/learnMap/SelectMapStatus';
import Pagination, { getPagination } from '@components/Pagination';
import SelectOrg from '@components/SelectOrg';
import SelectJobLevel from '@components/learnMap/SelectJobLevelNew';

import { getMapList, doDellearnMap, pullOnOff } from '@/api/learnMap/Index';
import Common from '@/utils/Common';
import MapStatistic from '@components/learnMap/MapStatistic';

const columns = [
  {
    width: '40px',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: '地图ID',
  },
  {
    width: '180px',
    align: 'center',
    title: '地图名称',
    dataIndex: 'title',
    key: 'title',
  },
  {
    width: '180px',
    align: 'center',
    title: '适用岗位-等级',
    key: 'post_arr',
    dataIndex: 'post_arr',
    scopedSlots: { customRender: 'post_arr' },
  },
  // {
  //   width: '80px',
  //   align: 'center',
  //   title: '适用等级',
  //   key: 'apply_level',
  //   dataIndex: 'apply_level',
  // },
  {
    width: '180px',
    align: 'center',
    title: '适用组织机构',
    dataIndex: 'org_arr',
    key: 'org_arr',
    scopedSlots: { customRender: 'org_arr' },
  },
  {
    width: '80px',
    align: 'center',
    title: '状态',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    width: '120px',
    align: 'center',
    title: '更新时间',
    dataIndex: 'updated_at',
    key: 'updated_at',
    scopedSlots: { customRender: 'updated_at' },
  },
  {
    width: '150px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'List',
  components: {
    CommonTitle,
    BaseLabelAction,
    SelectMapStatus,
    SelectOrg,
    SelectJobLevel,
    Pagination,
    MapStatistic,
  },
  data() {
    return {
      titles: [{ title: '地图管理' }, { title: '地图列表' }],
      listDefaultShownCount: 2,

      pathName: '', // 地图名称
      selectedStatus: undefined,
      readOnly: false,
      loading: false,
      data: [],
      columns,
      orgIds: '',
      postId: '',
      levelIds: '',
      pagination: getPagination(),

      record: {},
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    formatTime(time) {
      return Common.formatDateTime(time * 1000, true);
    },

    /* 选择适用岗位等级 */
    checkJobLevel({ job, level }) {
      this.postId = job || '';
      this.levelIds = level || '';
    },

    addPath(item) {
      const options = { path: 'detail' };
      item?.id && (options.id = item.id);

      this.$router.push(options);
    },
    /* 选择上架状态 */
    selectStatus(event) {
      this.selectedStatus = event?.id;
    },
    selectOrg(event) {
      const data = event?.data || [];
      this.orgIds = data.map(i => i.id).join(',');
    },
    /* 搜索地图列表 */
    onSearch() {
      console.log('搜索地图列表');
      this.onPageChange(1, this.pagination.pageSize);
    },
    /* 重置搜索条件 */
    onReset() {
      console.log('重置搜索条件');
      this.pathName = '';
      this.postId = '';
      this.levelIds = '';
      this.selectedStatus = '';
      this.orgIds = '';
      this.$refs.jobLevel.resetFileds();
      this.$refs.mapStatus.reset();
      this.$refs.selectOrg.clear();
    },
    editItem(item) {
      const path = `${ listSubPage[0]?.path }?id=${ item.id }&type=1`;
      this.$router.push(path);
    },
    // 查看
    toViewItem(item) {
      const path = `${ listSubPage[0]?.path }?id=${ item.id }&type=2`;
      this.$router.push(path);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        title: this.pathName,
        page: page,
        per_page: pageSize,
        org: this.orgIds || '',
        post: this.postId || '',
        level: this.levelIds || '',
      };
      if (this.selectedStatus !== undefined && this.selectedStatus !== null && this.selectedStatus > -1) {
        params.status = this.selectedStatus;
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getMapList(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '获取列表失败');
      }
      const list = data?.data || [];
      list.forEach((i) => {
        i.allPostShown = false;
        i.allOrgShown = false;
      });
      this.data = list;

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    toggleAllPostShown(record) {
      record.allPostShown = !record.allPostShown;
    },

    toggleAllOrgShown(record) {
      record.allOrgShown = !record.allOrgShown;
    },

    // 删除学习地图
    deleteRecord({ id }) {
      this.$confirm({
        content: '确认删除该学习地图？',
        onOk: () => this.confirmDeleteLearnMap(id),
      });
    },
    async confirmDeleteLearnMap(id) {
      if (this.data.length === 1) {
        this.pagination.current = this.pagination.current - 1;
      }
      console.log(this.pagination.current);
      const data = await doDellearnMap(id);

      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      await this.getData(this.pagination.current, this.pagination.pageSize);
    },
    // 上下架
    pullOnOff(item, toStatus) {
      this.$confirm({
        content: () => <div>确认{ toStatus === 1 ? '上' : '下' }架？</div>,
        onOk: () => {
          this.confirmPullOnOff(item, toStatus);
        },
      });
    },
    async confirmPullOnOff(item, toStatus) {
      this.loading = true;
      const data = await pullOnOff({ id: item.id }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error({ content: data?.message || ((toStatus ? '上' : '下') + '架失败') });
      }
      this.$message.success({ content: data?.message || ((toStatus ? '上' : '下') + '架成功') });
      await this.getData(this.pagination.current, this.pagination.pageSize);
    },

    viewStatistic(record) {
      this.record = record || {};

      this.$refs.mapStatisticRef?.show && this.$refs.mapStatisticRef.show({ record });
    },
  },
};
</script>

<style lang="scss" scoped>
.ac-map-list {
  overflow: auto;

  .search {
    display: flex;
    align-items: center;
    padding: 3px 15px 5px 30px;
  }

  & .row-wrap {
    flex-wrap: wrap;
  }

  .select-org {
    display: flex;
    align-items: center;
  }

  ::v-deep .ss-select {
    width: inherit;
  }

  .search-btn {
    margin-left: 30px;
  }
}
</style>
