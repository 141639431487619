<template>
  <div class="admin-learn-map-detail-verification">
    <div class="flex flex-start">
      <span class="title">信息填写完整</span>
      <span class="content pass">通过</span>
    </div>

    <div class="flex flex-start align-start mt-10">
      <span class="title">关卡条件有效</span>
      <span v-if="(!levelError || !levelError.length) && (!unlockError || !unlockError.length) && level_num !== 0"
            class="content pass">通过</span>
      <div v-else class="content">
        <!--<div>未通过</div>-->
        <div v-if="levelError && levelError.length" class="flex flex-start align-start">
          <div>关卡配置错误：</div>
          <div>
            <div v-for="(item, idx) of levelError" :key="idx">{{ item.title }}</div>
          </div>
        </div>
        <div v-if="unlockError && unlockError.length" class="flex flex-start align-start mt-10">
          <div>解锁条件配置错误：</div>
          <div>
            <div v-for="(item, idx) of unlockError" :key="idx">{{ item.title }}</div>
          </div>
        </div>
        <div v-if="level_num === 0" class="flex flex-start align-start">
          <div>没有配置关卡</div>
        </div>
      </div>
    </div>

    <div class="flex flex-start mt-20 empty-text">全部条件校验通过即可上架</div>

    <div class="flex flex-start mt-10">
      <a-button :disabled="status !== 0 || readOnly || !!levelError.length || !!unlockError.length"
                type="primary" @click="pullOnOff">
        上架
      </a-button>
      <a-button @click="back">返回列表</a-button>
    </div>
  </div>
</template>

<script>
import { checkMap, pullOnOff } from '@/api/learnMap/Index';
import { mapState } from 'vuex';
export default {
  name: 'Verification',
  data() {
    return {
      loading: false,

      id: '',

      status: undefined,
      level_num: 0,
      levelError: [],
      unlockError: [],
    };
  },
  computed: {
    ...mapState({
      readOnly: state => state.LearnMap.readOnly,
    }),
  },
  created() {
    this.id = this.$route.query?.id || '';

    this.id && this.checkMap();
    if (this.$route?.query?.type === '2') {
      this.$store.commit('LearnMap/updateReadOnly', true);
      console.log(this.$store.state.LearnMap.readOnly);
    }
  },
  methods: {
    back() {
      this.$router.replace('/index/learnMap/list')
    },

    async checkMap() {
      if (this.loading) {return;}
      this.loading = true;

      const data = await checkMap({ id: this.id }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }

      this.levelError = data?.data?.level_error || [];
      this.unlockError = data?.data?.unlock_error || [];
      this.level_num = data?.data?.level_num || 0;
      this.status = data?.data?.status || 0;
    },

    // 上下架
    pullOnOff() {
      if (this.readonly) {return;}

      this.$confirm({
        content: () => <div>确认上架？</div>,
        onOk: () => {
          this.confirmPullOnOff();
        },
      });
    },
    async confirmPullOnOff() {
      this.loading = true;
      const data = await pullOnOff({ id: this.id }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error({ content: data?.message || '上架失败' });
      }
      this.$message.success({ content: data?.message || '上架成功' });
      this.back();
    },
  },
};
</script>

<style scoped lang="scss">
.admin-learn-map-detail-verification {

  .title {
    width: 160px;
    text-align: right;
  }

  .content {
    margin-left: 40px;
    color: $danger01;
  }

  .pass {
    color: $success01;
  }

  .empty-text {
    font-size: 0.8em;
    color: #999999;
  }
}
</style>
