<template>
  <div class="apply-job-level">
    <span>适用岗位-等级：</span>
    <a-select
      class="apply-select"
      :filter-option="filterOption"
      v-model="applyJob"
      @change="handleChange"
      show-search>
      <template v-for="(item1) in jobOptions">
        <a-select-option
          :key="item1.id"
          :value="item1.id">
          {{ item1.name }}
        </a-select-option>
      </template>
    </a-select>
    <a-select
      class="apply-select ml-20"
      mode="multiple"
      v-if="levelOptions && levelOptions.length"
      v-model="applyLevel"
      @change="handleChangeLevel"
      show-search>
      <template v-for="(item1) in levelOptions">
        <a-select-option
          :key="item1.id"
          :value="item1.id">
          {{ item1.displayName }}
        </a-select-option>
      </template>
    </a-select>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name: "SelectJobLevelNew",
    computed: {
      ...mapState({
        jobOptions: state => state.LearnMap.jobLevelList,
      }),
    },
    data() {
      return {
        applyJob: '',
        applyLevel: [],
        levelOptions: [],
        searchParams: {
          job: '',
          level: ''
        }
      }
    },
    methods: {
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      handleChange(val) {
        console.log(val);
        const obj = this.jobOptions.find((item) => item.id === val);
        console.log(obj);
        if (obj.level && obj.level.length) {
          this.levelOptions = obj.level;
        } else {
          this.levelOptions = [];
        }
        this.searchParams.job = val;
        this.$emit('check-job-level', this.searchParams);
      },
      handleChangeLevel(val) {
        if (val && val.length) {
          this.searchParams.level = val.join(',');
        } else {
          this.searchParams.level = '';
        }
        this.$emit('check-job-level', this.searchParams);
      },
      resetFileds() {
        this.applyLevel = '';
        this.applyJob = '';
        this.searchParams.level = '';
        this.searchParams.job = '';
      }
    }
  }
</script>

<style lang="scss" scoped>
  .apply-job-level {
    display: flex;
    align-items: center;
    .apply-select {
      width: 200px;
    }
  }
</style>
