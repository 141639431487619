<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'LearnMap',
  created() {
    this.$store.dispatch('LearnMap/getJobLevelList', { all: 0, not_rz: 1, group: 0 });
  },
};
</script>

<style scoped lang="scss">

</style>
