<template>
  <a-modal :title="modalTitle"
           :visible="shown"
           :confirm-loading="confirmLoading"
           :mask-closable="false"
           :width="900"
           :centered="true"
           :destroy-on-close="true"
           wrap-class-name="level-detail-modal"
           @ok="confirm"
           @cancel="close(false)">

    <a-card title="完成条件" size="small" type="inner" class="mb-10">
      <a-table :columns="finishColumns" :data-source="finishList" :pagination="false"
               :loading="loading || confirmLoading" :bordered="false" :locale="locale" :title="() => ''"
               row-key="id" class="table small-cell-table">

        <template slot="type" slot-scope="text, record">
          <a-select v-model="record.type" :disabled="readOnly" placeholder="请选择事件类型" class="finish-type-select"
                    @select="clearFields(record, ['titleTrueId', 'titleId'])">
            <a-select-option v-for="(item, idx) of finishType" :key="idx" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="title" slot-scope="text, record">
          <a-select v-model="record.titleId" :allow-clear="true" :show-search="true" :filter-option="false"
                    placeholder="搜索课程名称" class="select-search block"
                    :disabled="readOnly"
                    @focus="getContentList($event, record.type)"
                    @search="getContentList($event, record.type)"
                    @select="selectFinishTitle">
            <a-select-option v-for="(item) of contentList" :key="item.id" :value="item.id">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </template>
      </a-table>
    </a-card>

    <a-card title="解锁条件(如无需求可不添加解锁条件)" size="small" type="inner" class="mb-10">
      <div slot="extra">
        <a-button @click="addUnlock" :disabled="readOnly">添加解锁条件</a-button>
        <!--        <span class="ml-20">如无需求可不添加解锁条件</span>-->
      </div>

      <a-table :columns="unlockColumns" :data-source="unlockList" :pagination="false"
               :loading="loading || confirmLoading" :bordered="false" :locale="locale" :title="() => ''"
               row-key="id" class="table small-cell-table">

        <template slot="unlock_type" slot-scope="text, record">
          <a-select v-model="record.unlock_type" placeholder="请选择类型" class="unlock-type-select"
                    :disabled="readOnly"
                    @select="clearFields(record, ['unlock_true_id', 'unlock_id', 'unlock_unit'])">
            <a-select-option v-for="(item, idx) of unlockType" :key="idx" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="unlock_title" slot-scope="text, record">
          <a-input v-if="!unlockContentShown(record.unlock_type)" :disabled="true" placeholder="不需要填写"/>
          <a-select v-else v-model="record.unlock_id" :allow-clear="true" :show-search="true" :filter-option="false"
                    placeholder="内容" class="select-search block"
                    :disabled="readOnly"
                    @focus="getContentList($event, record.unlock_type)"
                    @search="getContentList($event, record.unlock_type)"
                    @select="selectUnlockTitle(record)">
            <a-select-option v-for="(item) of contentList" :key="item.id" :value="item.id">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="unlock_condition" slot-scope="text, record">
          <a-select v-model="record.unlock_condition" :disabled="readOnly" placeholder="请选择解锁条件"
                    class="unlock-condition-select">
            <a-select-option v-for="(item, idx) of recordCondition(record.unlock_type)" :key="idx" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="unlock_length" slot-scope="text, record">
          <a-input-number v-model="record.unlock_length" :disabled="readOnly" placeholder="数量" :min="0"
                          :max="9999" class="unlock-length"></a-input-number>
        </template>
        <template slot="unlock_unit" slot-scope="text, record">
          <!--<a-select v-model="record.unlock_unit"  :disabled="readOnly" placeholder="请选择单位" class="unlock-unit-select">-->
          <a-select v-model="record.unlock_unit" :disabled="readOnly" placeholder="" class="unlock-unit-select">
            <a-select-option v-for="(item, idx) of recordUnit(record.unlock_type)" :key="idx" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button type="link" :disabled="readOnly" @click="deleteRow(record)">
            <a-icon type="close"/>
          </a-button>
        </template>
      </a-table>
    </a-card>

    <a-card title="奖励积分" size="small" type="inner" class="mb-10">
      <div slot="extra" class="integral-extra flex flex-start">
        <a-input-number
          :disabled="readOnly"
          v-model="integral"
          placeholder="请输入奖励积分" :min="0" :max="9999" :allow-clear="true"></a-input-number>
        <span class="integral-add-text ml-20 flex-none">分</span>
      </div>
    </a-card>

    <a-card title="优先级" size="small" type="inner" class="mb-10">
      <div slot="extra" class="order-extra flex flex-start">
        <a-input-number v-model="order" :disabled="readOnly" placeholder="请输入优先级" :min="1"
                        :allow-clear="true"></a-input-number>
      </div>
    </a-card>

  </a-modal>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import { getFinishType, createLevel, editLevel, getLevelDetail, getLevelContent } from '@/api/learnMap/Index';

const defaultFinishItem = { id: -1, type: undefined, title: '', titleId: '', titleTrueId: '' };
const defaultUnlockItem = {
  id: -1,
  unlock_type: undefined,
  unlock_id: undefined,
  unlock_true_id: undefined,
  unlock_title: '',
  unlock_condition: undefined,
  unlock_length: 0,
  unlock_unit: undefined,
};

const finishColumns = [
  {
    width: '100px',
    align: 'center',
    dataIndex: 'type',
    key: 'type',
    title: '请选择事件类型',
    scopedSlots: { customRender: 'type' },
  },
  {
    width: '300px',
    align: 'center',
    dataIndex: 'title',
    key: 'title',
    title: '名称',
    scopedSlots: { customRender: 'title' },
  },
];
const unlockColumns = [
  {
    width: '120px',
    align: 'center',
    dataIndex: 'unlock_type',
    key: 'unlock_type',
    title: '类型',
    scopedSlots: { customRender: 'unlock_type' },
  },
  {
    align: 'center',
    dataIndex: 'unlock_title',
    key: 'unlock_title',
    title: '内容',
    scopedSlots: { customRender: 'unlock_title' },
  },
  {
    width: '110px',
    align: 'center',
    dataIndex: 'unlock_condition',
    key: 'unlock_condition',
    title: '条件',
    scopedSlots: { customRender: 'unlock_condition' },
  },
  {
    width: '70px',
    align: 'center',
    dataIndex: 'unlock_length',
    key: 'unlock_length',
    title: '数量',
    scopedSlots: { customRender: 'unlock_length' },
  },
  {
    width: '60px',
    align: 'center',
    dataIndex: 'unlock_unit',
    key: 'unlock_unit',
    title: '单位',
    scopedSlots: { customRender: 'unlock_unit' },
  },
  {
    width: '5px',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: '',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'LevelDetailModal',
  computed: {
    modalTitle() {
      return (this.levelId ? '编辑' : '新建') + '关卡';
    },
    ...mapState({
      readOnly: state => state.LearnMap.readOnly,
    }),
    unlockContentShown() {
      return (unlock_type) => {
        const [type] = this.unlockType.filter((t) => t.id === unlock_type) || [];
        return !type?.read_only;
      };
    },
    recordCondition() {
      return (unlock_type) => {
        const [unlockType = {}] = this.unlockType.filter((i) => i.id === unlock_type);
        if (!unlockType?.condition?.length) {
          return this.condition;
        }
        return this.condition.filter((c) => unlockType.condition.includes(+c.id));
      };
    },
    recordUnit() {
      return (unlock_type) => {
        const [unlockType = {}] = this.unlockType.filter((i) => i.id === unlock_type);
        if (!unlockType?.unit?.length) {
          return this.unit;
        }
        return this.unit.filter((unit) => unlockType.unit.includes(+unit.id));
      };
    },
  },
  data() {
    this.getContentList = debounce(this.getContentList, 500);

    return {
      shown: false,
      loading: false,
      confirmLoading: false,
      locale: {
        emptyText: () => '暂无数据~',
      },

      mapId: '',
      levelId: '',

      integral: 0,
      order: 1,

      finishColumns: finishColumns,
      unlockColumns: unlockColumns,

      finishList: [
        { ...defaultFinishItem },
      ],
      unlockList: [],

      contentList: [],

      finishType: [],
      unlockType: [],
      condition: [],
      unit: [],
    };
  },
  created() {
    this.getFinishType();
  },
  methods: {
    show({ mapId, levelId }) {
      this.shown = true;

      this.mapId = mapId || '';
      this.levelId = levelId || '';

      this.levelId && this.getLevelDetail();
    },
    close(needReload) {
      this.shown = false;

      this.loading = false;
      this.confirmLoading = false;
      this.finishList = [{ ...defaultFinishItem }];
      this.unlockList = [];
      this.integral = 0;
      this.order = undefined;

      this.$emit('close', { needReload });
    },

    async getFinishType() {
      if (this.loading) {return;}
      this.loading = true;

      const data = await getFinishType().finally(() => this.loading = false);
      if (!data || data?.error_code) {return;}

      const result = data.data || {};

      this.finishType = this.parseId(result.finish_type || []);
      this.unlockType = this.parseId(result.unlock_type || []);
      this.condition = this.parseId(result.condition || []);
      this.unit = this.parseId(result.unit || []);
    },

    async getContentList(title, type) {
      const data = await getLevelContent({ title, type });
      if (!data || data.error_code) {return;}
      this.contentList = data?.data || [];
    },

    selectFinishTitle() {
      this.finishList[0].titleTrueId = null;
    },

    selectUnlockTitle(record) {
      record.unlock_true_id = null;
    },

    clearFields(record, clearFields) {
      if (!record || !clearFields?.length) {return;}
      clearFields.forEach((i) => (record[i] = undefined));
      this.contentList = [];
    },

    parseId(arr) {
      arr.forEach((i) => i.id && (i.id = +i.id));
      return arr;
    },

    addUnlock() {
      const list = this.unlockList?.length ? [...this.unlockList] : [];
      const item = { ...defaultUnlockItem };

      let minId = -1;
      list.forEach((i) => minId = i.id < minId ? i.id : minId);
      item.id = minId - 1;

      list.push(item);
      this.unlockList = list;
    },

    deleteRow(record) {
      if (record.type || record.content || record.condition || record.time || record.unit) {
        this.$confirm({
          content: () => <div>确认删除该行？</div>,
          onOk: () => this.confirmDeleteRow(record),
        });
      } else {
        this.confirmDeleteRow(record);
      }
    },

    confirmDeleteRow(record) {
      const list = this.unlockList?.length ? [...this.unlockList] : [];

      const idx = list.findIndex((i) => i.id === record.id);
      if (idx > -1) {
        list.splice(idx, 1);
      }

      this.unlockList = list;
    },

    async getLevelDetail() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await getLevelDetail({ id: this.levelId }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '获取详情失败');
      }

      const result = data?.data || {};
      this.integral = result.integral || 0;
      this.order = result.sort;
      const finishList = [...this.finishList];
      if (finishList.length > 0) {
        finishList[0].type = result.finish_type;
        finishList[0].titleTrueId = result.finish_id;
        finishList[0].titleId = result.finish_title;
        finishList[0].title = result.finish_title;
      }
      this.finishList = finishList;

      const unlockList = result.unlock || [];
      unlockList.forEach((i) => {
        i.unlock_true_id = i.unlock_id;
        i.unlock_id = i.unlock_title;
        i.unlock_unit && (i.unlock_unit = +i.unlock_unit);
      });
      this.unlockList = unlockList;
    },

    async confirm() {
      if (this.confirmLoading) {
        return;
      }
      this.confirmLoading = true;

      if (!this.check()) {
        this.confirmLoading = false;
        return;
      }

      const params = {
        id: this.levelId ? this.levelId : null,
        map_id: this.mapId,
        sort: this.order || 0,
        finish_type: this.finishList[0].type,
        finish_id: this.finishList[0].titleTrueId ? this.finishList[0].titleTrueId : this.finishList[0].titleId,
        integral: this.integral || 0,
      };

      const unlock = [];
      this.unlockList.forEach((i) => {
        const item = {
          unlock_type: i.unlock_type,
          unlock_id: i.unlock_true_id ? i.unlock_true_id : i.unlock_id,
          unlock_condition: i.unlock_condition,
          unlock_length: i.unlock_length,
          unlock_unit: i.unlock_unit,
        };
        item.id = i.id > 0 ? i.id : 0;
        unlock.push(item);
      });

      params.unlock = JSON.stringify(unlock);

      const data = await (this.levelId ? editLevel : createLevel)(params).finally(() => this.confirmLoading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '保存失败');
      }
      this.$message.success(data?.message || '保存成功');
      this.close(true);
    },

    check() {
      let msg = '';

      if (!this.finishList[0].type) {
        msg = '请选择完成事件类型';
      } else if (!this.finishList[0].titleTrueId && !this.finishList[0].titleId) {
        msg = '请选择完成事件名称';
      } else if (this.unlockList.some((i) => {
        if (!i.unlock_type) {
          msg = '请选择解锁事件类型';
        } else if (!i.unlock_id && this.unlockContentShown(i.unlock_type)) {
          msg = '请选择解锁内容';
        } else if (!i.unlock_condition) {
          msg = '请选择解锁条件';
        } else if (!i.unlock_unit) {
          msg = '请选择解锁单位';
        }
      })) {msg = msg + '';} else if (!this.order) {
        msg = '请填写优先级';
      }

      msg && this.$message.error(msg);

      return !msg;
    },

  },
};
</script>

<style scoped lang="scss">
.finish-type-select {
  min-width: 160px;
}

.unlock-type-select {
  width: 220px;
}

.unlock-condition-select {
  width: 110px;
}

.unlock-length {
  width: 70px;
}

.unlock-unit-select {
  width: 60px;
}

.select-search {
  max-width: 260px;
}

.integral-extra {
  padding-right: 400px;
}

.integral-add-text {
  width: 20px;
}

.order-extra {
  padding-right: 440px;
}

::v-deep {
  .ant-table-title {
    padding: 0 !important;
  }
}
</style>
