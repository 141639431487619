<template>
  <div class="admin-learn-map-detail full-page">
    <CommonTitle :titles="titles"></CommonTitle>

    <div class="row row-left">
      <a-menu v-model="selectedMenu" mode="horizontal" @select="selectMenu">
        <a-menu-item v-for="item of menus" :disabled="item.id !== '1' && !id" :key="item.id">
          {{ item.title }}
        </a-menu-item>
      </a-menu>
    </div>

    <div class="row">
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapDetail } from '@/utils/Navs/LearnMapNavs';
import CommonTitle from '@components/CommonTitle';

export default {
  name: 'Detail',
  components: { CommonTitle },
  computed: {
    ...mapState({
      readOnly: state => state.LearnMap.readOnly,
    }),
  },
  watch: {
    '$route.query.id'(id) {
      this.id = id || this.id;
    },
    '$route.query.type'(type) {
      this.type = type || this.type;
    },
  },
  data() {
    return {
      id: null,
      type: null,
      titles: [{ title: '地图列表' }, { title: '编辑地图' }],
      menus: mapDetail,
      selectedMenu: ['1'],
    };
  },
  created() {
    this.id = this.$route.query?.id || null;
    this.type = this.$route.query?.type || null;
  },
  destroyed() {
    this.$store.commit('LearnMap/updateReadOnly', false);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.executeSelection(to?.name || ''));
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.query?.id || null;
    this.executeSelection(to?.name || '');
    next();
  },
  methods: {
    executeSelection(name) {
      this.menus.forEach(menu => {
        if (menu.name === name) {
          this.selectedMenu = [menu.id];
        }
      });
    },
    selectMenu({ key }) {
      this.id = this.$route.query?.id || null;
      if (+key > 1 && !this.id) {
        this.selectedMenu = ['1'];
        this.$message.warning({ content: '请先保存' });
        return;
      }
      const path = this.getPath(key);
      this.$router.replace({ path, query: { id: this.id, type: this.type } });
    },
    getPath(key) {
      const [{ path }] = this.menus.filter(menu => menu.id === key + '' && menu.path);
      return path || '';
    },
  },
};
</script>

<style scoped lang="scss">
.admin-learn-map-detail {
  overflow-y: auto;
}
</style>
