<template>
    <div class="index-config full-page">
      <CommonTitle :titles="titles">
        <div slot="buttons" class="btn-group">
          <a-button @click="addEntrance">添加入口</a-button>
        </div>
      </CommonTitle>
      <div class="row">
        <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
                 row-key="id" class="table small-cell-table">
          <template slot="icon" slot-scope="text">
            <img :src="text" alt="" class="table-img"/>
          </template>
          <template slot="is_white" slot-scope="text1">
            <span v-if="text1 === 1">开启</span>
            <span v-if="text1 === 0">关闭</span>
          </template>
          <template slot="action" slot-scope="text, record">
            <div class="table-cell-wrapper">
              <a-button type="link" size="small" @click="handleEntranceDetail(record)">详情</a-button>
              <a-button type="link" size="small" @click="editEntrance(record)">编辑</a-button>
              <a-button type="link" size="small" @click="handleDelEntrance(record)">删除</a-button>
            </div>
          </template>
<!--          <template slot="footer">-->
<!--            <Pagination v-if="pagination && pagination.total" :pagination="pagination"-->
<!--                        class="pagination"-->
<!--                        @change="onPageChange"-->
<!--                        @showSizeChange="onSizeChange"></Pagination>-->
<!--          </template>-->
        </a-table>
      </div>
    </div>
</template>

<script>
  import CommonTitle from '@components/CommonTitle';
  import { getIndexConfigList, doDelEntrance } from '@/api/indexConfig/Index';
  const columns = [
    {
      width: '40px',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      title: 'ID',
    },
    {
      width: '180px',
      align: 'center',
      title: '入口名称',
      dataIndex: 'title',
      key: 'title'
    },
    {
      width: '160px',
      align: 'center',
      title: '图标',
      dataIndex: 'icon',
      key: 'icon',
      scopedSlots: {customRender: 'icon'},
    },
    {
      width: '180px',
      align: 'center',
      title: 'APP对应KEY',
      key: 'key',
      dataIndex: 'key',
    },
    {
      width: '100px',
      align: 'center',
      title: '排序',
      key: 'sort',
      dataIndex: 'sort',
    },
    {
      width: '100px',
      align: 'center',
      title: '白名单开启',
      key: 'is_white',
      dataIndex: 'is_white',
      scopedSlots: {customRender: 'is_white'},
    },
    {
      width: '130px',
      align: 'center',
      title: '白名单用户id',
      key: 'white_uids',
      dataIndex: 'white_uids',
    },
    {
      width: '150px',
      align: 'center',
      title: '操作',
      key: 'action',
      scopedSlots: {customRender: 'action'},
    },
  ];
  export default {
    name: "EntranceList",
    components: {
      CommonTitle,
    },
    data() {
      return {
        loading: false,
        titles: [{title: '入口管理'}, {title: '入口列表'}],
        columns,
        data: [], // 入口数据
      }
    },
    created() {
      this.getData()
    },
    methods: {
      // onPageChange(page, pageSize) {
      //   this.getData(page, pageSize);
      // },
      // onSizeChange(current, size) {
      //   this.getData(1, size);
      // },
      async getData() {
        // page = page || 1;
        // pageSize = pageSize || this.pagination.defaultPageSize;
        //
        // const params = {
        //   page: page,
        //   per_page: pageSize,
        // };
        if (this.loading) {
          return;
        }
        this.loading = true;
        const data = await getIndexConfigList().finally(() => this.loading = false);
        if (!data || data.error_code) {
          return;
        }
        this.data = data?.data || [];
      },
      /* 添加入口 */
      addEntrance() {
        this.$router.push({
          path: '/index/entrance/operation',
          query: {
            type: '0'
          }
        })
      },
      /* 查看入口详情 */
      handleEntranceDetail({ id }) {
        this.$router.push({
          path: '/index/entrance/operation',
          query: {
            type: '2',
            id,
          }
        })
      },
      /* 删除入口 */
      handleDelEntrance({ id }) {
        this.$confirm({
          content: '确认删除该入口吗？',
          onOk: () => this.goDel(id),
        });
      },
      async goDel(id) {
        const data = await doDelEntrance(id).finally(() => this.loading = false);
        if (!data || data.error_code) {
          this.$message.error(data.message);
          return;
        }
        await this.getData();
      },
      /* 编辑入口 */
      editEntrance({ id }) {
        console.log('编辑入口');
        this.$router.push({
          path: '/index/entrance/operation',
          query: {
            type: '1',
            id,
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .index-config {
    overflow-y: auto;
  }
</style>
