<template>
  <div class="admin-learn-map-detail-basic">

    <a-form :form="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 3 }">

      <a-form-item label="地图名称">
        <a-input :disabled="readOnly"
                 v-decorator="['title', { initialValue: title, rules: [{ required: true, message: '请输入地图名称!' }] }]"
                 placeholder="请输入地图名称"/>
      </a-form-item>

      <a-form-item label="完成文案" :wrapper-col="{ span: 6 }">
        <a-input :disabled="readOnly"
                 v-decorator="['finishContent', { initialValue: finishContent, rules: [{ required: true, message: '请输入完成文案!' }] }]"
                 placeholder="请输入完成文案"/>
      </a-form-item>

      <a-form-item label="完成图标">
        <div class="row row-left">
          <a-upload
            v-decorator="['coverUrl', { initialValue: coverUrl, rules: [{ required: true, message: '请上传封面图片!' }] }]"
            list-type="picture-card"
            style="width: auto; margin-bottom: -8px;"
            :disabled="readOnly"
            :show-upload-list="false"
            :custom-request="customRequest">
            <img v-if="coverUrl" :src="coverUrl" alt="封面" class="cover-img"/>
            <div v-else>
              <a-icon :type="picLoading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <div class="col col-left" v-if="coverUrl">
            <a-button @click="previewImg">查看</a-button>
            <a-button type="danger" @click="deleteCoverImg" :disabled="readOnly">删除</a-button>
          </div>
        </div>
      </a-form-item>

      <a-form-item label="适用岗位-等级" :wrapper-col="{ span: 20 }">
        <div class="apply-job-level" v-for="(item, index) in fillList" :key="index">
          <a-select
            class="apply-select"
            :filter-option="filterOption"
            v-model="item.job"
            :disabled="readOnly"
            @change="handleChange(index, $event)"
            show-search>
            <template v-for="(item1) in jobOptions">
              <a-select-option
                :key="item1.id"
                :value="item1.id">
                {{ item1.name }}
              </a-select-option>
            </template>
          </a-select>
          <a-checkbox-group
            v-if="item.levelOptions"
            :disabled="readOnly"
            v-model="item.levelCheckedList"
            class="ml-20"
          >
            <template v-for="item2 of item.levelOptions">
              <a-checkbox
                :key="item2.id"
                :value="item2.id"
                class="job-item">
                {{ item2.displayName }}
              </a-checkbox>
            </template>
          </a-checkbox-group>
          <a-button type="primary" @click="handleDelItem(index)" :disabled="readOnly">删除</a-button>
        </div>
        <a-button type="primary" @click="handleAddJob" :disabled="readOnly">添加岗位-等级</a-button>
      </a-form-item>

      <a-form-item label="适用组织机构" style="margin-bottom: 5px;">
        <SelectOrg :show-label="false" :disabled="readOnly" :default-selected-ids="org_ids"
                   @select="selectOrg"></SelectOrg>
      </a-form-item>

      <a-form-item label="前置地图" v-if="beforeMapList && beforeMapList.length">
        <a-select v-model="selectedBeforeMapId" placeholder="请选择前置地图" :allow-clear="true">
          <a-select-option v-for="(item) of beforeMapList" :key="item.id" :value="item.id">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="地图描述" :wrapper-col="{ span: 6 }">
        <a-textarea :disabled="readOnly"
          v-model="mapDescribe"
          placeholder="请输入地图描述" :rows="4" />
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 5}">
        <a-button :disabled="readOnly" type="primary" @click="handleSave">保存本页</a-button>
        <a-button :disabled="readOnly" type="primary" @click="saveNext">保存并下一页</a-button>
        <a-button @click="cancel">返回列表</a-button>
      </a-form-item>

    </a-form>

    <ImageViewer ref="imageViewerRef" :url="coverUrl"></ImageViewer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getBeforeMapList, createMap, getMap, editMap } from '@/api/learnMap/Index';
import { getImageUrl } from '@/api/Common'

import ImageViewer from '@components/ImageViewer';
import SelectOrg from '@components/SelectOrg';

export default {
  name: 'Basic',
  components: { ImageViewer, SelectOrg },
  computed: {
    ...mapState({
      readOnly: state => state.LearnMap.readOnly,
      jobOptions: state => state.LearnMap.jobLevelList,
    }),
  },
  data() {
    return {
      id: '',
      pageType: this.$route.query?.type || '0',
      title: '', // 地图名称
      finishContent: '', // 完成文案
      beforeMapList: [],
      org_ids: '',
      selectedBeforeMapId: null, // 前置地图
      jobLevelList: [],
      checkboxOption: [],
      fillList: [],
      defaultSelectedIds: '',
      jobLevel: '',
      mapDescribe: '', // 地图描述
      coverUrl: '',
      operationType: '', // 操作行为
      form: this.$form.createForm(this, { name: 'learnMap' }),
      picLoading: false,
    };
  },
  created() {
    this.id = this.$route.query?.id || '';
    this.getBeforeMapList();
    if (this.id) {
      this.handleGetLearnMapDetail();
    }
  },
  // destroyed() {
  //   this.$store.commit('LearnMap/updateReadOnly', false);
  // },
  methods: {
    /* 获取学习地图基础信息详情 */
    async handleGetLearnMapDetail() {
      const data = await getMap({ id: this.id });
      if (!data || data.error_code) {
        return;
      }
      console.log(data);
      this.title = data.data.title;
      this.finishContent = data.data.finish_content;
      this.mapDescribe = data.data.content;
      this.coverUrl = data.data.finish_icon;
      this.selectedBeforeMapId = data.data.before_id || '';
      this.org_ids = data.data.org_arr?.map((item) => item.org_id).join(',') || '';
      data.data.post_arr.forEach((item) => {
        const obj1 = this.jobOptions.find((item1) => item1.id === item.post_id);
        const obj = {
          job: item.post_id,
          level: '',
          levelOptions: [],
          levelCheckedList: []
        }
        if (obj1.level && obj1.level.length) {
          obj.levelOptions = obj1.level;
          obj.levelCheckedList = item.level_ids;
        }
        this.fillList.push(obj);
      })
      if (this.pageType === '2') {
        this.$store.commit('LearnMap/updateReadOnly', true);
      }
    },

    async getBeforeMapList() {
      const data = await getBeforeMapList({ id: this.id });
      if (!data || data.error_code) {
        return;
      }
      this.beforeMapList = data?.data || [];
      // if (this.pageType === '0') {
      //   this.selectedBeforeMapId = data?.data[0].id;
      // }
    },

    /* 保存本页面 */
    handleSave() {
      this.operationType = 'save';
      this.onSubmit();
    },

    /* 保存并下一页 */
    saveNext() {
      this.operationType = 'next';
      this.onSubmit();
    },

    onSubmit() {
      if (this.readOnly || this.loading) {
        return;
      }
      this.loading = true;

      this.form.validateFields(async (err, values) => {
        if (err) {
          this.$message.info({ content: '请填写必填项' });
          return this.loading = false;
        }
        if (!this.org_ids) {
          this.$message.warning('请选择适用的组织机构');
          return this.loading = false;
        }
        const params = {
          title: values.title,
          org_ids: this.org_ids,
          finish_icon: this.coverUrl,
          finish_content: values.finishContent,
          // before_id: this.selectedBeforeMapId,
          content: this.mapDescribe
        };
        this.selectedBeforeMapId && (params.before_id = this.selectedBeforeMapId);
        console.log(this.fillList);
        let arr = [];
        if (this.fillList && this.fillList.length) {
          this.fillList.forEach((item) => {
            let obj = {
              post: '',
              level: ''
            }
            obj.post = item.job;
            obj.level = item.levelCheckedList && item.levelCheckedList.join(',');
            arr.push(obj);
          })
        }
        if (!arr.length) {
          this.$message.warning('请选择适用岗位-等级');
          return this.loading = false;
        }
        params.post_level = JSON.stringify(arr);
        console.log(params);
        /* 0: 新增 1: 编辑 2: 详情 */
        if (this.pageType === '0') {
          await this.goAdd(params);
        } else if (this.pageType === '1') {
          this.id && (params.id = this.id);
          await this.goEdit(params);
        }

      });
    },
    /* 添加 */
    async goAdd(params) {
      const data = await createMap(params);

      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.loading = false;
      this.id = data.data?.id || '';
      this.pageType = '1'
      this.$message.success('保存成功!');
      if (this.operationType === 'next') {
        await this.$router.push({ path: '/index/learnMap/detail/level', query: {id: data.data?.id || ''} });
      }
    },
    /* 修改 */
    async goEdit(params) {
      console.log('修改');
      const data = await editMap(this.id, params);

      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.loading = false;
      this.$message.success('修改成功!');
      if (this.operationType === 'next') {
        await this.$router.push({ path: '/index/learnMap/detail/level', query: {id: this.id, type: this.pageType} });
      }
    },
    /* 选择岗位 */
    handleChange(ind, val) {
      const obj = this.jobOptions.find((item) => item.id === val);
      console.log(obj);
      if (obj.level && obj.level.length) {
        this.fillList[ind].levelOptions = obj.level;
      }
    },
    previewImg() {
      this.$refs?.imageViewerRef?.show && this.$refs.imageViewerRef.show();
    },
    deleteCoverImg() {
      this.coverUrl = '';
    },

    customRequest({file}) {
      this.uploadPic(file);
    },

    async uploadPic(data) {
      this.picLoading = true;
      const params = new FormData();
      params.append('file', data);
      const response = await getImageUrl(params).finally(() => this.picLoading = false);
      console.log(response);
      if (response.code === 200) {
        this.coverUrl = response?.url || '';
      } else  {
        this.$message.error({content: response.msg});
      }
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    /* 添加岗位等级 */
    handleAddJob() {
      const obj = {
        job: '',
        level: '',
        levelOptions: [],
        levelCheckedList: []
      }
      this.fillList.push(obj);
    },
    handleDelItem(ind) {
      this.fillList.splice(ind, 1);
    },
    cancel() {
      this.$router.replace('/index/learnMap/list')
    },
    selectOrg(val) {
      this.org_ids = val.data?.map((item) => item.id).join(',') || '';
      console.log(this.org_ids);
    }
  },
};
</script>

<style scoped lang="scss">
.admin-learn-map-detail-basic {
  width: 100%;
  height: 100%;
  overflow: auto;
  .apply-job-level {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .apply-select {
      width: 200px;
    }
  }
  .apply-job-level :last-of-type {
    margin-bottom: 0;
  }
  .cover-img {
    width: 110px;
  }
}
</style>
